import {onMounted, onUnmounted, onUpdated, Ref} from 'vue'

import {clamp, fitTo01} from '@/util'

function getOffsetTopFromBody(el: HTMLElement) {
	let top = 0
	let dom: HTMLElement | null = el

	while (dom) {
		top += dom.offsetTop
		dom = dom.offsetParent as HTMLElement
	}

	return top
}

interface WarpInfo {
	container: Ref<HTMLElement | null>
	elements: HTMLElement[]
	selector: string
	excludeSelector: string
}

const warpElementsList = new Set<WarpInfo>()

let header: HTMLElement
let content: HTMLElement

const updateWarpTransform = (info?: WarpInfo) => {
	if (!header) return

	const y = window.scrollY
	const marginTop = header.offsetHeight
	const vh = window.innerHeight

	const infos = info ? [info] : warpElementsList

	for (const {elements} of infos) {
		for (const el of elements) {
			const height = el.offsetHeight

			const top = getOffsetTopFromBody(el) - marginTop

			const ty = Math.max(0, y - top)

			const tsy = clamp(1 - (y - top) / height, 0, 1)
			const bsy = fitTo01(y + vh - marginTop, top, top + height)
			const sy = tsy * bsy

			el.style.transform = `translate3D(0, ${ty}px, 0) scale3D(1, ${sy}, 1)`
			el.style.visibility = sy <= 0 ? 'hidden' : 'visible'
		}
	}
}

const updateWarpElements = (info: WarpInfo) => {
	const {container, selector, excludeSelector} = info

	if (!container.value || !header) return

	const cadidates = Array.from(
		container.value.querySelectorAll(selector)
	) as HTMLElement[]

	const elementsToExclude = Array.from(
		container.value.querySelectorAll(excludeSelector)
	)

	const maxHeight = window.innerHeight - header.offsetHeight

	const traverseElements = (el: HTMLElement): HTMLElement[] => {
		if (maxHeight < el.offsetHeight || elementsToExclude.includes(el)) {
			// If the element should not be transformed, then traverse child elements
			const children = Array.from(el.childNodes).filter(
				c => c instanceof HTMLElement
			) as HTMLElement[]
			return children.flatMap(traverseElements)
		} else {
			return [el]
		}
	}

	const oldElements = info.elements
	const newElements = cadidates.flatMap(traverseElements)

	oldElements.forEach(el => delete el.dataset['warp'])
	newElements.forEach(el => (el.dataset['warp'] = ''))

	info.elements = newElements
}

let prevScrollY = -1
let leavingPageScrollY: null | number = null

const onRaf = () => {
	requestAnimationFrame(onRaf)

	const y = window.scrollY

	if (content) {
		// While transition
		if (content.childNodes.length > 1) {
			if (leavingPageScrollY === null) {
				leavingPageScrollY = prevScrollY
			}

			let leavingView: HTMLElement | null = null

			for (const view of Array.from(content.children)) {
				if (
					view instanceof HTMLElement &&
					view.getAttribute('class')?.includes('leave-active')
				) {
					leavingView = view
					break
				}
			}

			if (!leavingView) {
				// console.warn('Cannot find leaving view')
			} else {
				leavingView.style.top = `${y - leavingPageScrollY}px`
			}
		} else {
			leavingPageScrollY = null
		}
		content.style.transform = `translate3D(0, ${-y}px, 0)`
	}

	updateWarpTransform()

	prevScrollY = y
}
onRaf()

window.addEventListener('resize', () => {
	warpElementsList.forEach(updateWarpElements)
	updateWarpTransform()
})

export const setupWarpScroll = (option: {
	header: HTMLElement
	content: HTMLElement
}) => {
	header = option.header
	content = option.content
}
export const registerWarpScrollContent = (
	callback: () => HTMLElement | null
) => {
	onMounted(() => {
		const c = callback()
		if (!c) throw new Error('Cannot find the content element')
		content = c
	})
}

export const useWarpScroll = (
	container: Ref<HTMLElement | null>,
	selector = ':is(.warp, .warp-wrapper > *):not(style)',
	excludeSelector = '.wp-block-columns, .wp-block-column, .wp-block-image, .wp-block-gallery'
) => {
	const info: WarpInfo = {
		container,
		elements: [],
		selector,
		excludeSelector,
	}

	warpElementsList.add(info)

	const update = () => {
		updateWarpElements(info)
		updateWarpTransform(info)
	}

	onMounted(update)
	onUpdated(update)

	onUnmounted(() => {
		warpElementsList.delete(info)
	})
}
