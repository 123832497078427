<template lang="pug">
.RouteNews(ref='root')
	PaneSubmenu(
		:links='[{name: "All", link: "/news", split: true}, ...years]'
		active='None')
	.RouteNews__content(v-if='news')
		.header.warp
			h3.date {{news.date}}
			h2.title {{news.title}}
			h4.secondary-title(v-if='news.secondaryTitle' v-html='news.secondaryTitle')
		SectionContent.warp-wrapper(v-html='news.content')
	.RouteNews__content(v-else)
		.RouteNews__loading
</template>

<script lang="ts">
import {defineComponent, ref, watch} from 'vue'
import {useRoute} from 'vue-router'

import {useWarpScroll} from '@/use/useWarpScroll'
import * as wp from '@/wp'

import PaneSubmenu from './PaneSubmenu.vue'
import SectionContent from './SectionContent.vue'

export default defineComponent({
	name: 'RouteNews',
	components: {
		SectionContent,
		PaneSubmenu,
	},
	props: {
		postid: {
			type: String,
			required: true,
		},
	},
	emits: ['update:title'],
	setup(props, {emit}) {
		const news = ref<wp.Post | null>(null)
		const years = ref<wp.RestResponse<typeof wp.newsYears>>([])
		const root = ref<HTMLElement | null>(null)

		useWarpScroll(root)

		const route = useRoute()

		watch(
			() => [props.postid, route.meta.lang],
			async () => {
				const posts = await wp.posts({
					id: props.postid ? parseInt(props.postid) : undefined,
					lang: route.meta.lang as string,
				})

				if (posts.length !== 1) {
					throw new Error('404')
				}

				news.value = posts[0]

				emit('update:title', news.value.title)
			},
			{immediate: true}
		)

		wp.newsYears({}).then(val => (years.value = val))

		return {news, years, root}
	},
})
</script>

<style lang="stylus">
@import '@/common.styl'

.RouteNews

	&__content
		max-width 80rem
		contentPadding()

	.date
		font-serif()
		// color var(--highlight)
		font-size 1.25rem
		margin-bottom 1.6666rem

	.title
		font-heading-bold()
		font-size 2rem
		line-height 2.2rem
		margin-bottom 1.33333rem

		a:link
			text-decoration none

	.secondary-title
		font-heading-normal()
		line-height 2.08rem
		font-size 1.5rem

	.header
		margin-bottom 2.66666rem

	&__loading
		width 1em
		height 1em
		imageLazyLoad()
</style>
