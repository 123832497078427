import LazyLoad from 'vanilla-lazyload'
import {onMounted, onUnmounted, onUpdated, Ref} from 'vue'

export const useLazyLoad = (
	container: Ref<HTMLElement | null>,
	selector = 'img[data-src], img[data-srcset]'
) => {
	let lazyloadInstance: any = null

	const startLazyLoad = () => {
		if (!container.value) return

		lazyloadInstance?.destroy()

		lazyloadInstance = new LazyLoad({
			container: container.value,
			elements_selector: selector,
		})
		lazyloadInstance.loadAll()
	}

	onMounted(startLazyLoad)
	onUpdated(startLazyLoad)

	onUnmounted(() => {
		lazyloadInstance?.destroy()
	})
}
