import {restApiMemoize} from './util'

const fetchINS = async <T>(entrypoint: string, params: any = {}) => {
	const queryDict = Object.entries(params).filter(([, v]) => v !== undefined)
	queryDict.push(['qtranslate-mode', 'raw'])
	const query = new URLSearchParams(queryDict as any).toString()

	const res = await fetch(`/wp-json/ins/v1${entrypoint}?${query}`)
	if (!res.ok) {
		throw new Error('404')
	}

	const json = (await res.json()) as T
	return json
}

interface BaseOption {
	lang: string
}

export interface Post {
	id: number
	slug: string
	date: string
	modified: string
	thumbnail: string
	thumbnailAspect: number
	title: string
	link: string
	content: string
	artist: Artist[]
	isProfile: boolean
	secondaryTitle: string
}

//---------------------------------------------------------------------------
// Posts

interface PostOption extends BaseOption {
	id?: number
	type?: string
	slug?: string
	artists?: string[]
	year?: string
}

export const posts = restApiMemoize(
	(option: PostOption) => fetchINS<Post[]>('/post', option),
	(option: PostOption, posts: Post[]) => {
		return posts.flatMap(post => [
			[{lang: option.lang, id: post.id}, [post]],
			[{lang: option.lang, slug: post.slug}, [post]],
		])
	}
)

//---------------------------------------------------------------------------
// Artists
interface Artist {
	slug: string
	name: string
	link: string
}

export const artists = restApiMemoize((option: BaseOption) =>
	fetchINS<Artist[]>('/artists', option)
)

//---------------------------------------------------------------------------
// News Year

interface NewsYear {
	name: string
	link: string
}

export const newsYears = restApiMemoize(() =>
	fetchINS<NewsYear[]>('/news/years')
)

//---------------------------------------------------------------------------
// Other Exports
export type RestResponse<T extends (...args: any[]) => any> = Awaited<
	ReturnType<T>
>
