<template lang="pug">
.SectionContent(ref='root')
	slot
</template>

<script lang="ts">
import {defineComponent, onMounted, onUpdated, ref} from 'vue'

import {router} from '@/router'
import {useLazyLoad} from '@/use/useLazyLoad'

export default defineComponent({
	name: 'SectionContent',
	setup() {
		const root = ref<HTMLElement | null>(null)

		// Convert internal links to RouterLink
		const convertInternalLinks = () => {
			if (!root.value) return

			const anchors = root.value.getElementsByTagName('a')

			for (const a of Array.from(anchors)) {
				const url = a.getAttribute('href')

				if (!url || !url.startsWith('/')) {
					continue
				}

				a.addEventListener('click', e => {
					e.preventDefault()
					router.push(url)
				})
			}
		}

		onMounted(convertInternalLinks)
		onUpdated(convertInternalLinks)

		useLazyLoad(root)

		return {root}
	},
})
</script>

<style lang="stylus">
@import '@/common.styl'

.SectionContent
	font-serif()
	line-height 1.75rem
	letter-spacing 0.025em

	& > *:first-child
		margin-top 0

	@import '@/content.styl'
</style>
