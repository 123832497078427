<template lang="pug">
.PaneMenu
	.PaneMenu__top
		h1.PaneMenu__title: a(@click='onClickSiteName') INS Studio
		ul.PaneMenu__lang
			li
				span(v-if='$route.meta.lang === "en"') En
				RouterLink(v-else :to='enLink') En
			li
				span(v-if='$route.meta.lang === "ja"') 日
				RouterLink(v-else :to='jaLink') 日
	ul.PaneMenu__menu
		li(:class='menuClass("view")'):  RouterLink(to='/view') View
		//- li(:class='menuClass("news")'): RouterLink(to='/news') News
		li(:class='menuClass("artists")'):  RouterLink(to='/artists') Artists
		li(:class='menuClass("shop")'): RouterLink(to='/shop') Shop
		//- li(:class='menuClass("info")'):  RouterLink(to='/info') Info
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue'
import {useRoute} from 'vue-router'

import {router} from '@/router'

export default defineComponent({
	name: 'PaneMenu',
	setup() {
		const route = useRoute()

		const enLink = computed(() => ({
			...route,
			params: {...route.params, lang: 'en'},
		}))
		const jaLink = computed(() => ({
			...route,
			params: {...route.params, lang: 'ja'},
		}))

		function menuClass(name: string) {
			let active = name === route.meta.menu

			if (route.meta.menu === 'home') {
				active = true
			}

			return {active}
		}

		const onClickSiteName = () => {
			if (route.meta.menu === 'home') return

			router.push('/')
		}

		return {
			menuClass,
			enLink,
			jaLink,
			onClickSiteName,
		}
	},
})
</script>

<style lang="stylus">
@import '@/common.styl'

.PaneMenu
	width 100%
	font-heading-normal()
	z-index 100
	position fixed
	background-color var(--bg)
	top 0
	display flex
	height var(--menu-variable-height)
	flex-direction column
	overflow hidden
	pageTransition(height background-color)


	&:before
		content ''
		display block
		position absolute
		z-index 0
		top 0
		left 0
		background url('/wp-content/uploads/2022/07/ins0728_2-1280x961.jpg')
		background-position 50% 50%
		background-size cover
		background-repeat no-repeat
		width 100%
		height var(--first-view-height)
		opacity calc(var(--menu-ease-color) * 0.5)
		transform-origin 0 0
		transform scale3D(1, calc(.2 + var(--menu-ease) * 0.8), 1)
		pageTransition(opacity transform filter)


	span, a
		html:not(.wf-neue-haas-grotesk-display-n5-active) &
			box-shadow 0 0 0 1px var(--border)
			color transparent !important

	a
		cursor pointer
		text-decoration none

		// &.external:after
		// 	display inline-block
		// 	content ''
		// 	width .6em
		// 	margin-left .2em
		// 	margin-right .1em
		// 	margin-top .3em
		// 	vertical-align top
		// 	opacity .3
		// 	height @width
		// 	background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22black%22%20d%3D%22M432%2C320H400a16%2C16%2C0%2C0%2C0-16%2C16V448H64V128H208a16%2C16%2C0%2C0%2C0%2C16-16V80a16%2C16%2C0%2C0%2C0-16-16H48A48%2C48%2C0%2C0%2C0%2C0%2C112V464a48%2C48%2C0%2C0%2C0%2C48%2C48H400a48%2C48%2C0%2C0%2C0%2C48-48V336A16%2C16%2C0%2C0%2C0%2C432%2C320ZM488%2C0h-128c-21.37%2C0-32.05%2C25.91-17%2C41l35.73%2C35.73L135%2C320.37a24%2C24%2C0%2C0%2C0%2C0%2C34L157.67%2C377a24%2C24%2C0%2C0%2C0%2C34%2C0L435.28%2C133.32%2C471%2C169c15%2C15%2C41%2C4.5%2C41-17V24A24%2C24%2C0%2C0%2C0%2C488%2C0Z%22%2F%3E%3C%2Fsvg%3E');
		// 	background-repeat none
		// 	background-size 100% 100%

	.active a.external:after
			opacity 1

	&__top
		flex-grow 1
		z-index 1
		border-bottom 1px solid var(--border)
		display flex
		line-height 2rem
		padding 1rem var(--page-padding-side)
		position relative
		background-color transparent
		pageTransition(color background-color border-bottom)

	&__title
		position relative
		font-size calc(1.25rem * (1 - var(--menu-ease)) + 4rem * var(--menu-ease))
		align-self center
		text-align center
		width calc(4.5em * (1 - var(--menu-ease)) + (100vw - 2 * var(--page-padding-side)) * var(--menu-ease))
		white-space nowrap
		color var(--text)
		pageTransition(color font-size width)

	&__lang
		font-size 1.25rem
		position absolute
		list-style none
		display flex
		top 0
		right var(--page-padding-side)
		line-height calc(var(--menu-height) - 4rem)
		pageTransition(color)

		span
			color var(--text)

		a:link, a:visited
			color var(--gray)
			pageTransition(color)

		li:not(:last-child):after
			content '/'
			padding 0 .4em
			color var(--text)

	&__menu
		padding 0 var(--page-padding-side)
		border-bottom 1px solid var(--border)
		z-index 1
		list-style none
		height 4rem
		line-height @height
		font-size 1.25rem
		display flex
		color var(--text)
		pageTransition(border-bottom color)

		li:not(.active)
			color var(--gray)
			pageTransition(color)

		li:not(:last-child)
			margin-right 2.5rem
</style>
