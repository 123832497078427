<template lang="pug">
.RouteArtists
	PaneSubmenu(:links='artists')
	SectionWorkList.RouteArtists__workList
</template>

<script lang="ts">
import {defineComponent, ref, watch} from 'vue'
import {useRoute} from 'vue-router'

import * as wp from '@/wp'

import PaneSubmenu from './PaneSubmenu.vue'
import SectionWorkList from './SectionWorkList.vue'

export default defineComponent({
	name: 'RouteArtists',
	components: {
		PaneSubmenu,
		SectionWorkList,
	},
	emits: ['update:title'],
	setup(_, {emit}) {
		const artists = ref<wp.RestResponse<typeof wp.artists>>([])
		const route = useRoute()
		emit('update:title', 'Artists')

		watch(
			() => route.meta.lang as string,
			async lang => {
				artists.value = await wp.artists({lang})
			},
			{immediate: true}
		)

		return {artists}
	},
})
</script>

<style lang="stylus">
@import '@/common.styl'

.RouteArtists
	&__workList
		contentPadding()
</style>
