<template lang="pug">
.RouteNewsList(ref='root')
	PaneSubmenu(
		:links='[{name: "All", link: "/news", split: true}, ...years]'
		:active='year ?? "All"')
	.RouteNewsList__content
		RouterLink.RouteNewsList__entry.warp(v-for='post in news' :to='post.link')
			h3.date {{post.date}}
			.RouteNewsList__thumb(v-html='post.thumbnail')
			h2.title: span.text {{post.title}}
			h4.secondary-title(v-if='post.secondaryTitle')
				span(v-html='post.secondaryTitle')
</template>

<script lang="ts">
import {defineComponent, ref, watch} from 'vue'
import {useRoute} from 'vue-router'

import {useLazyLoad} from '@/use/useLazyLoad'
import {useWarpScroll} from '@/use/useWarpScroll'
import * as wp from '@/wp'

import PaneSubmenu from './PaneSubmenu.vue'

export default defineComponent({
	name: 'RouteNewsList',
	components: {
		PaneSubmenu,
	},
	props: {
		year: {
			type: String,
			default: undefined,
		},
	},
	emits: ['update:title'],
	setup(props, {emit}) {
		const news = ref<wp.Post[]>([])
		const years = ref<wp.RestResponse<typeof wp.newsYears>>([])
		const root = ref<HTMLElement | null>(null)

		useWarpScroll(root)
		useLazyLoad(root)

		const route = useRoute()

		watch(
			() => [props.year, route.meta.lang],
			async () => {
				news.value = await wp.posts({
					type: 'news',
					year: props.year as string,
					lang: route.meta.lang,
				})

				// Update title
				if (props.year) {
					emit('update:title', `News in ${props.year}`)
				} else {
					emit('update:title', 'News')
				}
			},
			{immediate: true}
		)

		wp.newsYears({}).then(val => (years.value = val))

		return {news, years, root}
	},
})
</script>

<style lang="stylus">
@import '@/common.styl'

.RouteNewsList

	&__content
		display grid
		grid-template-columns repeat(auto-fill, minmax(25rem, 1fr))
		gap 2rem
		contentPadding()

	a&__entry
		text-decoration none

		img
			margin 2px
			display block
			width 100%
			height auto
			imageLinkNormal()

		+hover()
			background none
			color var(--text)

			span
				background-color var(--text)
				color var(--bg)
				simpleTransition(background-color)

			img
				imageLinkHover()



	.date
		font-serif()
		margin-bottom .8rem

	.title
		font-heading-bold()
		font-size 1.5rem
		line-height 1.2em
		margin-top 1em
		margin-bottom .4em

		a:link
			text-decoration none

	.secondary-title
		font-heading-normal()
		line-height 2.08rem
		font-size 1.25rem

	.header
		margin-bottom 2.66666rem
</style>
