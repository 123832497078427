<template lang="pug">
nav.PaneSubmenu(ref='root')
	ul.warp
		li.loading(v-if='links.length === 0')
		li(v-else v-for='{name, link, split} in links' :key='link'
			:class='{active: active === "" || name === active, split}')
			RouterLink(:to='link') {{name}}
	hr
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from 'vue'

import {useWarpScroll} from '@/use/useWarpScroll'

interface Link {
	name: string
	link: string
	split?: boolean
}

export default defineComponent({
	name: 'PaneSubmenu',
	props: {
		links: {
			type: Array as PropType<Link[]>,
			default: () => [],
		},
		active: {
			type: String,
			default: '',
		},
	},
	setup() {
		const root = ref<HTMLElement | null>(null)

		useWarpScroll(root)

		return {root}
	},
})
</script>

<style lang="stylus">
@import '@/common.styl'
.PaneSubmenu

	ul
		min-height 4.5rem
		font-serif()
		padding 1.5rem var(--page-padding-side)
		list-style none
		display flex
		flex-wrap wrap
		gap .5em 0

	li
		position relative
		white-space nowrap

		&.loading
			width 1em
			height 1em
			imageLazyLoad()

		&:not(.active)
			color var(--gray)

		&:not(:last-child)
			padding-right calc(var(--page-padding-side) * 1.8)

		&.split
			$gap = calc(var(--page-padding-side) * 1)
			padding-right $gap

			&:after
				content '|'
				padding-left $gap
				color var(--text)

	a:link
		text-decoration none

		&:after
			content '　'
			margin-right -1em
</style>
