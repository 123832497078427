<template lang="pug">
ul.SectionNewsList.warp(ref='root')
	li(v-for='{title, link, id, date} in posts' :key='id')
		RouterLink(:to='link') {{date}} | {{title}}
</template>

<script lang="ts">
import {defineComponent, PropType, ref, watch} from 'vue'
import {useRoute} from 'vue-router'

import * as wp from '@/wp'

export default defineComponent({
	name: 'SectionNewsList',
	props: {
		hasContent: {
			type: Boolean,
		},
		artists: {
			type: Array as PropType<string[]>,
			default: undefined,
		},
	},
	emits: ['update:hasContent'],
	setup(props, {emit}) {
		const root = ref<HTMLElement | null>(null)
		const posts = ref<wp.Post[]>([])

		const route = useRoute()

		watch(
			() => props.artists,
			async artists => {
				posts.value = await wp.posts({
					lang: route.meta.lang,
					type: 'news',
					artists,
				})

				emit('update:hasContent', posts.value.length > 0)
			},
			{immediate: true}
		)

		return {root, posts}
	},
})
</script>
