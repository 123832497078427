import {
	createRouter,
	createWebHistory,
	RouterOptions,
	RouterView,
} from 'vue-router'

import RouteArtists from './views/RouteArtists.vue'
import RouteNews from './views/RouteNews.vue'
import RouteNewsList from './views/RouteNewsList.vue'
import RouteSingular from './views/RouteSingular.vue'

const routes: RouterOptions['routes'] = [
	{
		path: '/:lang(en|ja)?',
		component: RouterView,
		children: [
			{
				path: '',
				name: 'home',
				component: RouteSingular,
				props: () => ({slug: 'view'}),
				meta: {menu: 'home'},
			},
			{
				path: 'news',
				name: 'news',
				component: RouteNewsList,
				meta: {menu: 'news'},
			},
			{
				path: 'news/year\\::year(\\d+)',
				name: 'news-year',
				component: RouteNewsList,
				props: true,
				meta: {menu: 'news'},
			},
			{
				path: 'news/:postid(\\d+)',
				name: 'news-single',
				component: RouteNews,
				props: true,
				meta: {menu: 'news'},
			},
			{
				path: 'artists',
				name: 'artists',
				component: RouteArtists,
				meta: {menu: 'artists'},
			},
			{
				path: ':slug',
				name: 'singular',
				component: RouteSingular,
				props: true,
				meta: {menu: 'artists'},
			},
		],
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// NOTE: Little bit dirty,
		// but needs to expand the height of spacer in advance of scroll
		const spacer = document.getElementById('warpSpacer')
		const header = document.getElementById('warpHeader')

		if (!(spacer instanceof HTMLElement && header instanceof HTMLElement)) {
			throw new Error()
		}

		let top = 0
		let infiniteDelay = false
		let behavior: 'auto' | 'smooth' = 'auto'

		const headerHeight = header.offsetHeight
		const viewOffset = window.innerHeight - headerHeight

		if (from.name === 'home' && to.params.slug === 'view') {
			// Home -> View
			if (window.scrollY === 0) {
				top = viewOffset
				behavior = 'smooth'
			} else {
				infiniteDelay = true
			}
		} else if (savedPosition) {
			top = savedPosition.top
		} else if (to.params.slug === 'view') {
			top = viewOffset
		}

		if (infiniteDelay) {
			return new Promise(() => undefined)
		} else {
			const minHeight = top + window.innerHeight

			spacer.style.minHeight = `calc(${minHeight}px - var(--menu-height) * (1 - var(--menu-ease)))`

			return {
				top,
				behavior,
			}
		}
	},
})

// Switch the language
router.beforeResolve((_to, from, next) => {
	let to = _to

	const onFirstLoad = !from.name

	let lang = localStorage.getItem('lang')

	if (!localStorage.getItem('lang')) {
		lang = navigator.language.includes('ja') ? 'ja' : 'en'
		localStorage.setItem('lang', lang)
	}

	// console.log('to=', to, 'lang=', lang)

	if (!onFirstLoad && to.params.lang && !to.redirectedFrom) {
		// When the lang is explicitly specified
		// console.log('Explicitly specified', to.params.lang)
		lang = to.params.lang as string
		localStorage.setItem('lang', lang)
	}

	if (
		to.path === '/ja/' ||
		(to.name === 'singular' && to.params.slug === 'ja')
	) {
		// Redirect to home
		// console.log('Redirect: to home')
		next({name: 'home', params: {lang}})
	} else if (onFirstLoad && !to.redirectedFrom) {
		// console.log('on First load and rediret to', lang)
		next({...to, params: {...to.params, lang}})
	} else if (from.params.lang && !to.params.lang && !to.redirectedFrom) {
		// In the case that the destination has no language but the current page has
		// explicit language prefix
		// console.log('Redirect: Add a language prefix')
		next({
			...to,
			params: {...to.params, lang: from.params.lang},
		})
	} else if (to.params.lang === 'en') {
		// Omit the default language 'en' (English) from URI prefix
		// console.log('Redirect: Omit the default language prefix')
		next({
			...to,
			params: {...to.params, lang: ''},
		})
	} else {
		// console.log('Redirect terminated')
		next()
	}
})

// Transition
const menuOrder = ['view', 'news', 'artists', 'shop', 'info']

// Disable page transitions on macOS Safari,
// as it automatically inserts slide animations in certain situations
// (on clicking backward/forward buttons on the browser)
if (
	/macintosh/i.test(navigator.userAgent) &&
	/safari/i.test(navigator.userAgent) &&
	!/(chrome|firefox)/i.test(navigator.userAgent)
) {
	window.addEventListener('popstate', () => {
		document.documentElement.classList.remove('enable-page-transition')
	})
}

// Set route meta fields dynamically
router.beforeEach((to, from, next) => {
	// Language
	to.meta.lang = (to.params.lang as string) || 'en'

	// Set menu
	if (to.meta.menu === 'artists') {
		if (menuOrder.includes(to.params.slug as string)) {
			to.meta.menu = to.params.slug as string
		}
	}

	next()
})

router.afterEach((to, from) => {
	const fromMenu = from.meta.menu
	const toMenu = to.meta.menu

	// Set transition
	let transition = 'dissolve'

	if (
		(fromMenu === 'home' && toMenu === 'view') ||
		(fromMenu === 'view' && toMenu === 'home')
	) {
		transition = 'none'
	} else if (fromMenu === 'home') {
		transition = 'from-menu'
	} else if (toMenu === 'home') {
		transition = 'to-home'
	} else {
		const fromIndex = menuOrder.findIndex(m => m === fromMenu)
		const toIndex = menuOrder.findIndex(m => m === toMenu)

		if (fromIndex === -1 || toIndex === -1) {
			transition = 'dissolve'
		} else if (fromIndex < toIndex) {
			transition = 'slide-left'
		} else if (toIndex < fromIndex) {
			transition = 'slide-right'
		}
	}

	to.meta.transitionName = transition

	// Enable/disable page transition
	const doTransition = !!from.name && transition !== 'none'
	document.documentElement.classList.toggle(
		'enable-page-transition',
		doTransition
	)
})

export {router}

declare module 'vue-router' {
	interface RouteMeta {
		menu: string
		transitionName?: string
		lang?: string
	}
}
