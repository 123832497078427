<template lang="pug">
.App(:style='appStyle')
	#warpHeader(ref='warpHeader')
	PaneMenu(:style='menuStyle')
	.App__mainWrapper
		main.App__main#warpContent
			RouterView(v-slot="{Component, route}")#routerView
				transition(
					:name='route.meta.transitionName'
					@afterEnter="onPageTransitionFinished")
					component.App__routerView(
						:is="Component"
						:key='transitionKey'
						@update:title='onChangeTitle')
	.App__mainSpacer#warpSpacer
</template>

<script lang="ts">
import {useElementSize} from '@vueuse/core'
import chroma from 'chroma-js'
import {computed, defineComponent, onMounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'

import {setupWarpScroll} from '@/use/useWarpScroll'

import {clamp, fitTo01} from './util'
import PaneMenu from './views/PaneMenu.vue'
import SectionContent from './views/SectionContent.vue'

export default defineComponent({
	name: 'App',
	components: {PaneMenu, SectionContent},
	setup() {
		const route = useRoute()

		// Title
		const title = ref(document.title.replace(/( - )?INS Studio$/, ''))

		const onChangeTitle = (t: string) => {
			title.value = t
		}

		watch(title, () => {
			if (title.value === '') {
				document.title = 'INS Studio'
			} else {
				document.title = `${title.value} _ INS`
			}
		})

		// Landing page parallax
		const isLandingPage = computed(() => {
			return route.name === 'home' || route.params.slug === 'view'
		})

		const scrollRatio = ref(0)

		const warpHeader = ref<HTMLElement | null>(null)
		const warpHeaderHeight = useElementSize(warpHeader).height

		const onRaf = () => {
			requestAnimationFrame(onRaf)
			const ratio =
				1 - window.scrollY / (window.innerHeight - warpHeaderHeight.value)

			scrollRatio.value = clamp(ratio, 0, 1)
		}
		onRaf()

		const menuEase = computed(() =>
			isLandingPage.value ? scrollRatio.value : 0
		)

		const menuEaseColor = computed(() => {
			return easeInOutCubic(fitTo01(menuEase.value, 0.2, 0.6))

			function easeInOutCubic(x: number): number {
				return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
			}
		})

		// Color interpolation
		const black = chroma('#000')
		const white = chroma('#fff')
		const darkGray = chroma('#777')
		const lightGray = chroma('#A5A5A5')

		watch(
			() => menuEaseColor.value,
			t => {
				// Set body's background
				document.body.style.backgroundColor = chroma.mix(white, black, t).css()
			},
			{immediate: true}
		)

		const appStyle = computed(() => {
			return {
				'--menu-ease': menuEase.value,
			}
		})

		const menuStyle = computed(() => {
			const blend = menuEaseColor.value

			return {
				'--bg': chroma.mix(white, black, blend),
				'--text': chroma.mix(black, white, blend),
				'--border': chroma.mix(black, white, blend),
				'--gray': chroma.mix(lightGray, darkGray, blend),
				'--menu-ease-color': blend,
			}
		})

		// Typekit Timeout
		setTimeout(() => document.documentElement.classList.add('wf-active'), 2000)

		// Setup warp transform
		onMounted(() => {
			const header = document.getElementById('warpHeader')
			const content = document.getElementById('warpContent')
			const spacer = document.getElementById('warpSpacer')

			if (!header || !content || !spacer) {
				throw new Error('Cannot setup warp scroll')
			}

			setupWarpScroll({
				header,
				content,
			})

			// Automatically adjust the height of spacer to be equal to content
			new ResizeObserver(() => {
				spacer.style.height = content.offsetHeight + 'px'
			}).observe(content)
		})

		// Page transition
		const onPageTransitionFinished = () => {
			document.documentElement.classList.remove('enable-page-transition')
		}

		const transitionKey = computed(() => {
			const isView = route.name === 'singular' && route.params.slug === 'view'

			if (isView) {
				const languagePrefix = route.params.lang ?? ''
				return '/' + languagePrefix
			} else {
				return route.path
			}
		})

		return {
			appStyle,
			menuStyle,
			warpHeader,
			onChangeTitle,
			onPageTransitionFinished,
			transitionKey,
		}
	},
})
</script>

<style lang="stylus">
@import './root.styl'
@import './common.styl'

nested-reset()

// Vue-router transitions
.slide-left, .slide-right, .dissolve, .from-menu, .to-menu
	&-leave-active
		position absolute
		visibility visible

// Horizontal animation
.slide-left, .slide-right
	&-enter-active, &-leave-active
		pageTransition(transform)
		transform scale3D(0, 1, 1)

	&-enter-to, &-leave-from
		transform scale3D(1, 1, 1)

	&-enter-from, &-leave-to
		transform scale3D(0, 1, 1)

.slide-left
	&-enter-active
		transform-origin 100% 0

	&-leave-active
		transform-origin 0 0

.slide-right
	&-enter-active
		transform-origin 0 0

	&-leave-active
		transform-origin 100% 0

// Fade animation
.dissolve
	&-enter-active, &-leave-active
		transition-duration $simple-duration

	&-leave-active
		filter grayscale(1)
		mask(var(--mask), 2px 2px)

// Transition between home and other pages
.to-home
	&-enter-active, &-leave-active
		transition-duration $page-duration

	&-enter-active
		visibility hidden

.from-home
	&-enter-active, &-leave-active
		transition-duration $page-duration

	&-leave-active
		visibility hidden

html:not(.enable-page-transition)
	.slide-left, .slide-right, .dissolve, .from-menu, .to-menu
		&-enter-active
			position absolute
			transition none !important

		&-leave-active
			position absolute
			visibility hidden

.App
	position relative
	color var(--text)
	font-serif()
	background #fff

	scheme()

	html.wf-neue-haas-grotesk-display-n5-active.wf-neue-haas-grotesk-display-n6-active &,
	html.wf-active &
		--text #000
		--gray #A5A5A5

	#warpHeader
		pointer-events none
		height var(--menu-height)

	&__mainWrapper
		position fixed
		top 0
		width 100%
		padding-top var(--menu-height)
		transform-origin 0 var(--first-view-height)
		transform scale3D(1, calc(1 - var(--menu-ease)), 1)
		pageTransition(transform)

	&__errorMessage
		contentPadding()
		.back
			text-decoration underline

	&__routerView
		width 100%
		will-change transform

		&:before, &:after
			top 0
			content ''
			display block
			position absolute
			width 1px
			height 100%
			min-height var(--first-view-height)
			background var(--border)

		&:before
			left -1px

		&:after
			right -1px
</style>
