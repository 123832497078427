export const clamp = (x: number, min: number, max: number) =>
	Math.max(min, Math.min(max, x))

export const fitTo01 = (x: number, min: number, max: number) =>
	clamp((x - min) / (max - min), 0, 1)

export const restApiMemoize = <Option, ReturnType>(
	fn: (option: Option) => Promise<ReturnType>,
	additionalCacher: (
		option: Option,
		ret: ReturnType
	) => [Option, ReturnType][] = () => []
) => {
	const cache = new Map<string, ReturnType>()

	return async (option: Option) => {
		// https://stackoverflow.com/questions/16167581/sort-object-properties-and-json-stringify
		const key = JSON.stringify(option, Object.keys(option).sort())

		const cached = cache.get(key)
		if (cached) {
			return cached
		}

		const ret = await fn(option)

		cache.set(key, ret)

		for (const [o, r] of additionalCacher(option, ret)) {
			const k = JSON.stringify(o)
			cache.set(k, r)
		}

		return ret
	}
}
