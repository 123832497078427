<template lang="pug">
.PanePageTitle
	h2.PanePageTitle__title.warp {{ title }}
	hr
</template>

<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
	name: 'PanePageTitle',
	props: {
		title: {
			type: String,
			default: '',
		},
	},
})
</script>

<style lang="stylus">
@import '@/common.styl'

.PanePageTitle
	&__title
		font-heading-normal()
		font-size 1.25rem
		line-height 1.2rem
		height (2.8 * 2 + @line-height)rem
		padding 2.8rem var(--page-padding-side)

	hr
		margin 0
		border 0
		height 1px
		background-image linear-gradient(90deg, var(--border) 75%, transparent 75%)
		background-size 8px 1px
		background-repeat repeat-x
		background-position left bottom
</style>
