<template lang="pug">
.RouteSingular(ref='root' :class='{isLandingPage}')
	.RouteSingular__content(v-if='post === "loading"')
		.RouteSingular__loading
	template(v-else-if='post !== "error"')
		PaneSubmenu(v-if='post?.artist.length' :links='post.artist')
		PanePageTitle(v-if='!isRootPage' :title='post?.title')
		SectionContent.RouteSingular__content
			section(v-html='post?.content').warp-wrapper
			section(v-if='post?.isProfile')
				h2.warp(v-show='hasWorks')
				SectionWorkList(:artists='[slug]' v-model:hasContent='hasWorks')
				h2.warp(v-show='hasNews') News
				SectionNewsList(:artists='[slug]' v-model:hasContent='hasNews')
	template(v-else)
		SectionContent.RouteSingular__content.warp-wrapper
			p 404 Not Found
			p: a.back.link(@click='$router.back()') ⇤ Go Back
			
</template>

<script lang="ts">
import {
	computed,
	defineComponent,
	onMounted,
	onUnmounted,
	ref,
	watch,
} from 'vue'
import {useRoute} from 'vue-router'

import {router} from '@/router'
import {useWarpScroll} from '@/use/useWarpScroll'
import * as wp from '@/wp'

import PanePageTitle from './PanePageTitle.vue'
import PaneSubmenu from './PaneSubmenu.vue'
import SectionContent from './SectionContent.vue'
import SectionNewsList from './SectionNewsList.vue'
import SectionWorkList from './SectionWorkList.vue'

export default defineComponent({
	name: 'RouteSingular',
	components: {
		PanePageTitle,
		PaneSubmenu,
		SectionContent,
		SectionWorkList,
		SectionNewsList,
	},
	props: {
		slug: {
			type: String,
			required: true,
		},
	},
	emits: ['update:title'],
	setup(props, {emit}) {
		const route = useRoute()
		const post = ref<wp.Post | 'loading' | 'error'>('loading')
		const root = ref<HTMLElement | null>(null)

		const isRootPage = computed(() => {
			return ['view', 'info', 'shop'].includes(props.slug)
		})

		// NOTE: To prevent throwing errors twice
		watch(
			() => [props.slug, route.meta.lang] as const,
			async ([slug, lang]) => {
				const posts = await wp.posts({slug, lang: lang as string})
				if (posts.length === 0) {
					post.value = 'error'
					emit('update:title', '404')
				} else {
					post.value = posts[0]

					if (route.name === 'home') {
						emit('update:title', '')
					} else {
						emit('update:title', post.value.title)
					}
				}
			},
			{immediate: true}
		)

		useWarpScroll(root)

		const hasWorks = ref(false)
		const hasNews = ref(false)

		const isLandingPage = computed(() => props.slug === 'view')

		const onScroll = () => {
			if (route.name === 'home' && window.scrollY > 0) {
				router.push('/view')
			} else if (isLandingPage.value && window.scrollY <= 0) {
				router.push('/')
			}
		}

		onMounted(() => {
			window.addEventListener('scroll', onScroll)
		})
		onUnmounted(() => {
			window.removeEventListener('scroll', onScroll)
		})
		return {post, root, isLandingPage, isRootPage, hasWorks, hasNews}
	},
})
</script>

<style lang="stylus">
@import '@/common.styl'

.RouteSingular

	&__loading
		width 1em
		height 1em
		imageLazyLoad()

	&.isLandingPage
		padding-top calc(var(--first-view-height)  * (1 - var(--menu-ease))- var(--menu-height))

	&__content
		max-width $content-max-width
		contentPadding()

	&__workList
		padding var(--page-padding-side)
</style>
