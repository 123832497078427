<template lang="pug">
.SectionWorkList(ref='root')
	.SectionWorkList__work.loading(v-if='works === null')
	.SectionWorkList__work(v-else v-for='work in works' :key='work.id')
		RouterLink.warp(
			:to='work.link'
			:style='computeThumbStyle(work.thumbnailAspect)'
			v-html='work.thumbnail')
</template>

<script lang="ts">
import {defineComponent, PropType, ref, watch} from 'vue'
import {useRoute} from 'vue-router'

import {useLazyLoad} from '@/use/useLazyLoad'
import {useWarpScroll} from '@/use/useWarpScroll'
import * as wp from '@/wp'

export default defineComponent({
	name: 'SectionWorkList',
	props: {
		artists: {
			type: Array as PropType<string[]>,
			default: undefined,
		},
		hasContent: {
			type: Boolean,
		},
	},
	emits: ['update:hasContent'],
	setup(props, {emit}) {
		const route = useRoute()
		const root = ref<HTMLElement | null>(null)
		const works = ref<wp.Post[] | null>(null)

		watch(
			() => props.artists,
			async artists => {
				works.value = await wp.posts({
					lang: route.meta.lang as string,
					type: 'work',
					artists,
				})

				emit('update:hasContent', works.value.length > 0)
			},
			{immediate: true}
		)

		function computeThumbStyle(aspect: number) {
			const wrapperAspect = 13 / 15
			const area = 0.6

			const maxWidth = Math.min(1, wrapperAspect / aspect)

			const width = Math.sqrt(area / aspect)

			const clampedWidth = Math.min(width, maxWidth)

			return {width: `${clampedWidth * 100}%`}
		}

		useLazyLoad(root)
		useWarpScroll(root)

		return {root, works, computeThumbStyle}
	},
})
</script>

<style lang="stylus">
@import '@/common.styl'

.SectionWorkList
	display flex
	flex-wrap wrap
	gap 4.3rem

	&__work
		position relative
		width 15rem
		aspect-ratio 15 / 13
		list-style none
		display flex
		align-items center
		justify-content center

		&.loading
			imageLazyLoad()

	a, img
		display block

	img
		width 100%
		height auto
		position relative
		imageLazyLoad()
		imageLinkNormal()

	a
		+hover()
			img
				imageLinkHover()

	@media only screen and (max-width 500px)
		justify-content center
		gap 2rem

		&__work
			width 12rem
</style>
